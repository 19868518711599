.user-layout {
  &.fixed.ant-layout,
  &.fixed .full-layout.ant-layout {
    background-color: #f5f6fa;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .ant-layout-sider > .ant-layout-sider-children {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .page.ant-layout > .ant-layout-content {
    background-color: #f5f6fa;
    .ant-card-body {
      //background-color: #ffffff;
    }
    .card-product {
      .ant-card-head-title {
        padding-top: 10px;
        padding-bottom: 0;
      }
      .ant-card-head {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0;
        padding: 0 12px;
        min-height: 40px;
      }
      .ant-card-body,
      .ant-card-head {
        background-color: #ffffff;
      }
      .ant-card-body {
        padding-top: 12px;
      }
    }
    .card-order-list {
      width: 100%;
      text-align: center;
      th,
      td {
        font-size: 12px;
      }
    }
    .ant-card {
      .ant-collapse > .ant-collapse-item {
        background-color: white;
        margin-bottom: 12px;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 30px;
        border-bottom: #f0f6fb solid 1px;
      }
      .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow {
        left: 10px;
      }
      .ant-collapse-ghost
        > .ant-collapse-item
        > .ant-collapse-content
        > .ant-collapse-content-box {
        padding-top: 10px;
        padding-bottom: 5px;
      }
    }

    .card-collapse .ant-card-body {
      padding: 15px 12px 5px;
    }

    .icon-order-num {
      color: #cccccc;
    }
  }
}
.ant-tooltip-content {
  .ant-tooltip-inner {
    background-color: white;
    color: black;
    border: #cccccc 1px solid;
  }
  .ant-tooltip-arrow {
    color: #cccccc;
  }
  .ant-tooltip-arrow-content {
    background-color: rgba(0, 0, 0, 0.45);
  }
}
.cost-title {
  border-bottom: #f0f6fb 1px solid;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.user-layout .page.ant-layout > .ant-layout-content .card-product-info {
  margin-bottom: 20px;
}

.user-layout
  .page.ant-layout
  > .ant-layout-content
  .card-product-info
  > .ant-card-body {
  background-color: white;
  padding: 14px 12px 0;
}
.copybtn {
  font-size: 12px;
  background-color: #1a4fc9;
  color: #ffffff;
  padding: 3px 5px 5px;
  border-radius: 3px;
}
.deposit-line {
  height: 1px;
  background-color: #d8d8d8;
  margin: 20px 0 15px;
}
.deposit-desc {
  font-size: 12px;
}
.qrcode-desc {
  color: #8f8f8f;
}
.deposit-address-info {
  text-align: center;
}
.deposit-address-panel {
  color: #515151;
  padding: 10px 0;
}
.ant-card.wallet-balance-info {
}

.ant-card.wallet-balance-info .ant-card-body {
  height: 100px;
}

.ant-card.wallet-balance-info .ant-card-body .ant-statistic {
  margin-left: 5px;
}

.ant-card.wallet-balance-info .ant-card-body .ant-statistic-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.ant-card.wallet-balance-info .ant-card-body .ant-statistic-content {
  margin-top: 10px;
}

.ant-card.wallet-balance-info
  .ant-card-body
  .ant-statistic-content
  .ant-statistic-content-value {
  color: #0c68c6;
}

.ant-card.wallet-balance-info
  .ant-card-body
  .ant-statistic-content
  .ant-statistic-content-suffix {
}

.wallet-actions {
  text-align: right;
}

.wallet-actions-btn-1 {
  margin-top: 28px;
}

.wallet-actions-btn-2 {
  margin-top: 7px;
}

.agent-menu {
  .ant-menu-item {
    background-color: #ffffff;
  }
}

.agent-menu.ant-menu-inline,
.agent-menu.ant-menu-vertical,
.agent-menu.ant-menu-vertical-left {
  border-right: none;
}

.agent-packages-table-form {
  width: 100%;
  th {
    height: 30px;
    text-align: right;
  }
  td {
    text-align: left;
    padding-left: 15px;
  }
}

.column-common,
.ant-table-thead > tr > th.column-common {
  font-size: 12px;
  text-align: center;
}

.column-commision {
  width: 50px;
}

.btnBuyLink {
  font-size: 12px;
  float: right;
}
.space {
  width: 100%;
  height: 20px;
  clear: both;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.width-half {
  width: 50%;
}
table.table-product-info {
  width: 100%;
  th,
  td {
    padding: 2px 0;
    height: 28px;
  }
  td:last-child {
    padding-right: 8px;
  }
  .product-item {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }
}

.card-product-info-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 14px 12px 0;
}

.card-product-info-content {
  padding: 10px 10px 20px;
}

.product-title {
  color: rgba(16, 16, 16, 100);
  font-size: 16px;
  margin-bottom: 5px;
  img {
    margin-right: 5px;
  }
}

.product-price-value {
  color: #fa982d;
}

.ant-btn-primary-custom {
  border-radius: 5px;
}

.ant-btn-primary-custom,
.ant-btn-primary-custom.ant-btn:focus,
.ant-btn-primary-custom.ant-btn:hover {
  color: #fff;
  background: #3b5deb;
  border-color: #bbbbbb;
}

.am-button.am-button-primary.am-button-active,
.am-button.am-button-primary.am-button-active:focus,
.am-button.am-button-primary.am-button-active:hover,
.am-button.am-button-primary:focus,
.am-button.am-button-primary:hover {
  color: #fff;
  background-color: #3b5deb;
}

.wallet-title {
  font-size: 16px;
  height: 48px;
  line-height: 42px;
}
.wallet-item {
  height: 35px;
  line-height: 35px;
  font-size: 16px;
}
.wallet-symbol {
  color: #3a89e3;
  position: relative;
  margin-left: 20px;
  .wallet-symbol-icon {
    top: 5px;
    position: absolute;
    width: 24px;
  }
}
.wallet-symbol-text {
  margin-left: 40px;
}
.wallet-balance {
  color: #494a4d;
}
.gain-title {
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  margin-bottom: 5px;
}

.total-balance {
  color: #3a89e3;
  margin-left: 5px;
}
.powerPerT {
  color: #3a89e3;
  font-size: 16px;
}
.status-Active {
  color: #61b13e;
}
.make-order-quantity .ant-input-number-input {
  color: #3a89e3;
}
.merchant-customer {
  color: #3a89e3;
}
.order-agreement {
  color: #3a89e3;
}
.cost-item {
  font-weight: bold;
}
.order-total-power {
  font-size: 16px;
}
a.ant-btn.order-need-pledged {
  line-height: 23px;
  height: 23px;
  font-size: 14px;
  padding: 0 15px;
}

.am-tab-bar {
  max-width: 700px;
  margin: 0 auto;

  .loading-spinner-style1 {
    margin-top: 15px;
  }
}

.my-page {
  .wallet-symbol {
    color: #494a4d;
    margin-left: 12px;
  }
  .wallet-symbol-text {
    margin-left: 30px;
  }
}

.am-modal-wrap .am-modal-transparent {
  max-width: 540px;
  width: 95%;
}

.login-page .login-form a.am-button-primary {
  color: #ffffff;
}

.order-profile-table {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  background-color: #f6f6f6;
  td {
    line-height: 25px;
    padding: 10px 0;
  }
  .line {
    font-size: 20px;
  }
}

.order-profile-quantity {
  font-weight: bold;
}

.order-profile-title {
  font-size: 15px;
  padding: 10px 5px;
}
.card-radius {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 0 auto;
  background-color: #ffffff;
}

.card-radius.order-radius {
  background-color: #251dc5;
  padding: 20px 20px 10px;
}

.order-profile-date {
  background-color: rgb(5, 70, 204);
  color: white;
  padding: 4px 15px;
  font-size: 12px;
  margin: 8px 0 10px;
}

.ant-card.order-card {
  padding: 5px 0;
  border-radius: 0;
  .ant-card-body {
    padding: 0;
    text-align: center;
  }
  .ant-statistic-title {
    font-size: 12px;
    margin: 0;
  }
  .ant-statistic-content {
    font-size: 12px;
    line-height: 14px;
    height: 14px;
  }
  .ant-statistic-content-value {
    font-weight: bold;
  }
}
.order-sub-quantity {
  font-size: 14px;
  margin-left: 10px;
}

.order-card.green {
  .ant-statistic-title,
  .ant-statistic-content {
    color: green;
  }
}

.order-card.red {
  .ant-statistic-title,
  .ant-statistic-content {
    color: red;
  }
}

@primary-color: #3B5DEB;@brand-primary: #3B5DEB;